<template>
  <div class="wl-home">
    <div class="actListBox">
      <div class="actList" :class="{ downType: downType }">
        <div
          @click="actItem(item.id)"
          :class="{ act: act == item.id }"
          class="item"
          v-for="(item, index) in actList"
          :key="index"
          :label="item.name"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="downBut" @click="downType = !downType">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-fenlei"></use>
        </svg>
      </div>
    </div>

    <cont-item
      :it="item"
      v-for="(item, index) in contList"
      :key="index"
    ></cont-item>
  </div>
</template>

<script>
import Store from '../../store';
import ContItem from './cont-item.vue';
export default {
  components: { ContItem },
  mounted() {
    this.getVideoStateList();
  },

  data() {
    return {
      uti: Store.uti,
      contList: [],
      loading: false,
      finished: false,
      actList: [],
      act: 'first0',
      activeName: 'first0',
      list: [],
      pageNum: 1,
      pageSize: 2,
      total: 0,
      downType: false,
    };
  },
  methods: {
    actItem(val) {
      console.log(val);
      this.act = val;
      this.activeName = val;
      this.pageNum = 1;
      this.list = [];
      this.finished = false;
      this.loading = true;
      // 如果是精品
      this.getDocDet();
    },

    // 获取分类
    async getVideoStateList() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(`/contract/network/list`);
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      console.log(res.data);
      this.actList = res.data;
      this.act = res.data[0].id;
      this.getDocDet();
    },
    // 获取地址列表
    async getDocDet() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(
        `/contract/type/list/${this.act}`
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      console.log(res.data);
      this.contList = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.wl-home {
  .actListBox {
    display: flex;
    padding: (10 / 3.75vw) (10 / 3.75vw) (20 / 3.75vw);
    min-height: (40 / 3.75vw);
    background-color: #fff;

    .actList {
      width: 1px;
      flex: 1;
      // display: flex;
      // overflow: hidden;
      overflow: auto;
      min-height: (30 / 3.75vw);
      // flex-wrap: wrap;
      white-space: nowrap;
      .item {
        margin-right: (30 / 3.75vw);
        height: (35 / 3.75vw);
        line-height: (35 / 3.75vw);
        flex: 0;
        // width: 150px;
        font-size: (14 / 3.75vw);
        display: inline-block;
        font-size: #999;
      }
      .act {
        color: #ff7d41;
        border-bottom: 2px solid #ff7d41;
        //
      }
    }
    .downType.actList {
      // flex-wrap: wrap;
      white-space: normal !important;
    }
    .downBut {
      margin-left: (10 / 3.75vw);
      font-size: (18 / 3.75vw);
      padding-top: (7.5 / 3.75vw);
    }
  }
  // height: 600px;
  // margin-top: 200px;
  padding-bottom: 50px;
}
</style>
